*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 100%; //1 rem = 16px;

  @include respond(desktop) {
    font-size: 87.5%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
}


.whatsapp-float{
  position: fixed;
  bottom: 0px;
  right: 5px;
  cursor: pointer;
}
