#blog-images {
  #blog-images-hero-img {
    max-width: 35%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .copy-image {
    cursor: pointer;
    width: 1.5rem;
    top: 1rem;
    right: 2.5rem;
  }
  .del-image {
    cursor: pointer;
    width: 1.5rem;
    top: 3rem;
    right: 2.5rem;
  }
}
