#showreelPage {
  #showreel-hero-img {
    max-width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .yt-container {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .sc-container {
    width: 100%;
    aspect-ratio: 3 / 1;
  }

  .youtube {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .soundcloud {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .hero-desc {
    text-align: justify;
    text-justify: inter-word;
  }
}
