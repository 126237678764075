#artistPage {
  .celeb-card {
    border-radius: 1rem;
    .celeb-img {
      border-radius: 0.75rem;
    }
  }

  .card {
    border-radius: 2rem;

    .tags p {
      max-width: 18rem;

      @include respond(desktop) {
        max-width: 16rem;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // .artist-icon {
  // }
  .btn-share,
  .btn-dwn {
    cursor: pointer;
    width: 1.75rem;
  }

  .whatsapp-sample-icon {
    cursor: pointer;
    width: 1.4rem;
  }

  #floating-artist-icons {
    top: 2rem;
    right: 2rem;
  }

  .artist-icon {
    cursor: pointer;
    // top: 2rem;
    // right: 2rem;
    width: 2rem;
  }

  .share-icon2 {
    cursor: pointer;
    // top: 4.5rem;
    // right: 2rem;
    width: 2rem;
  }

  .whatsapp-artist-icon {
    cursor: pointer;
    // top: 7rem;
    // right: 2rem;
    width: 2rem;
  }

  .icon {
    width: 20px;
  }
  .icon-width {
    // display: inline-block;
    max-width: 18px;
    max-height: 18px;
    margin-right: 8px;
  }
  .icon-width2 {
    // width: 20px !important;
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
  }
  .icon-width3 {
    // width: 20px !important;
    max-width: 20px;
    max-height: 18px;
    margin-right: 8px;
  }
  .truncate {
    // width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #gender-icon,
  #artist-codename {
    margin-left: 5px;
    width: 20px;
  }
  #voice-quality-icon,
  #artist-location {
    margin-left: 6px;
    width: 18px;
  }
  #artist-category-icon,
  #voice-age-icon,
  #artist-about {
    margin-left: 7px;
    width: 16px;
  }

  .about {
    text-align: justify;
    text-justify: inter-word;
  }

  .artist-call,
  .artist-whatsapp {
    cursor: pointer;
  }

  // Ribbons
  .ribbon {
    position: absolute;
    // z-index: 1000;
    max-width: 5rem;
    top: 2rem;
    left: -0.2rem;
  }

  // Avatars - transparent bg
  .avatar {
    width: 12rem;
    height: 12rem;
    @include respond(tab-land) {
      width: 10rem;
      height: 10rem;
    }
  }
}
