#servicesPage {
  #services-hero {
    max-width: 45%;
    @include respond(tab-port) {
      max-width: 55%;
    }
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    // width: 50%;
  }

  .link-icon {
    width: 2rem;
  }

  .hero-desc,
  .service-desc {
    text-align: justify;
    text-justify: inter-word;
  }
  // Services icons
  .services-img-50 {
    max-width: 50%;
    @include respond(tab-port) {
      max-width: 60%;
    }
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
