#header {
  // Logos
  #voicesbazaar-logo,
  .voicesbazaar-logo {
    height: 4rem;
  }


  a {
    text-decoration: none;
    padding: 0 1rem;
  }

  .shift-down {
    padding: 2.2rem 0;
  }

  .main-menu {
    @include respond(tab-land) {
      display: none !important;
    }
  }

  .hamburger {
    &__button {
      display: none;

      @include respond(tab-land) {
        display: block;
        cursor: pointer;
      }
    }

    //ICON
    &__icon {
      margin-top: 2rem;

      &,
      &::before,
      &::after {
        width: 2rem;
        height: 2px;
        background-color: #333;
        display: inline-block;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        transition: all 0.1s;
      }

      &::before {
        top: -0.6rem;
      }
      &::after {
        top: 0.6rem;
      }
    }

    &__button:hover .hamburger__icon::before {
      top: -0.8rem;
    }

    &__button:hover .hamburger__icon::after {
      top: 0.8rem;
    }

    &:checked ~ .hamburger__button .hamburger__icon {
      background-color: transparent;
    }

    &:checked ~ .hamburger__button .hamburger__icon::before {
      top: 0;
      transform: rotate(135deg);
    }

    &:checked ~ .hamburger__button .hamburger__icon::after {
      top: 0;
      transform: rotate(-135deg);
    }
  }

  .floating-menu {
    display: none;
  }

  @include respond(tab-land) {
    .hamburger:checked ~ .floating-menu {
      display: block;
    }
  }
}

#register {
  #register-countrycode {
    max-width: 7rem;
  }
}

.login-margin{
  margin-left:52.9rem !important;
}