.audio-player {
  width: 16rem;
  @include respond(desktop) {
    width: 14rem;
  }
  @include respond(tab-land) {
    width: 13rem;
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // margin-top: 10px;
  }
  .player-button {
    background-color: transparent;
    border: 0;
    width: 2.5rem;
    height: 2.5rem;
    // @include respond(tab-land) {
    //   width: 1.5rem;
    //   height: 1.5rem;
    // }
    cursor: pointer;
    padding: 0;
  }

  .timeline {
    -webkit-appearance: none;

    width: 10rem;

    @include respond(desktop) {
      width: 8rem;
    }
    @include respond(tab-land) {
      width: 7rem;
    }
    height: 0.4em;
    background-color: #e5e5e5;
    border-radius: 5px;
    background-size: 0% 100%;
    background-image: linear-gradient(#de5e97, #de5e97);
    background-repeat: no-repeat;
    // margin-right: var(--space);
    // margin-left: var(--space);
  }

  .timeline::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: #a94672;
  }

  .timeline::-moz-range-thumb {
    -webkit-appearance: none;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: #a94672;
  }

  .timeline::-ms-thumb {
    -webkit-appearance: none;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: #a94672;
  }

  .timeline::-webkit-slider-thumb:hover {
    background-color: #943f65;
  }

  .timeline:hover::-webkit-slider-thumb {
    opacity: 1;
  }

  .timeline::-moz-range-thumb:hover {
    background-color: #943f65;
  }

  .timeline:hover::-moz-range-thumb {
    opacity: 1;
  }

  .timeline::-ms-thumb:hover {
    background-color: #943f65;
  }

  .timeline:hover::-ms-thumb {
    opacity: 1;
  }

  .timeline::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  .timeline::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  .timeline::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  .sound-button {
    background-color: transparent;
    border: 0;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    padding: 0;
  }
}

// ****************************************************************************
// 2nd Variant
.audio-player2 {
  width: 18rem;
  @include respond(desktop) {
    width: 16rem;
  }

  .timeline {
    width: 12rem;

    @include respond(desktop) {
      width: 10rem;
    }
  }
}

// ****************************************************************************
// 3rd Variant
.audio-player3 {
  width: 18rem;
  @include respond(desktop) {
    width: 16rem;
  }
  @include respond(phone-sm) {
    width: 14rem;
  }

  .timeline {
    width: 12rem;

    @include respond(desktop) {
      width: 10rem;
    }

    @include respond(phone-sm) {
      width: 8rem;
    }
  }
}
