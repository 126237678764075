#blogs {
  #blogs-hero-img {
    max-width: 45%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-image {
    max-width: 100%;
    max-height: 20rem;
  }
  .card {
    border-radius: 2rem;
  }
}
