#home {
  .celeb-scroll {
    overflow-y: hidden;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .celeb-scroll::-webkit-scrollbar {
    display: none;
  }

  .celeb-card {
    transition: transform 300ms;
  }

  .celeb-card:hover {
    transform: scale(1.08);
  }

  .vertical-rl {
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(180deg);
    /* Firefox */
    -moz-transform: rotate(180deg);
    /* IE */
    -ms-transform: rotate(180deg);
    /* Opera */
    -o-transform: rotate(180deg);
  }

  .main-heading {
    font-size: 3rem;

    @include respond(tab-land) {
      font-size: 2.5rem;
    }

    @include respond(tab-port) {
      font-size: 2rem;
    }
  }

  .hero-img {
    display: block;
    margin-left: auto;
    max-width: 95%;
    height: auto;
    @include respond(desktop) {
      max-width: 90%;
    }
    @include respond(phone) {
      margin: auto;
    }
  }

  .home-brand-div {
    min-height: 80px;
  }

  .home-brands-60 {
    width: 60px;
  }

  .home-brands-80 {
    width: 80px;
  }

  .home-brands-100 {
    width: 100px;
  }

  .home-brands-120 {
    width: 120px;
  }

  .home-brands-140 {
    width: 140px;
  }

  .home-brands-160 {
    width: 160px;
  }

  .home-brands-180 {
    width: 180px;
  }

  .home-brands-200 {
    width: 200px;
  }

  .brand-video,
  .how-it-works {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
    border-radius: 3rem;

    .how-r {
      min-width: 15%;
    }
  }

  #testimonials {
    .testimonial {
      text-align: justify;
      text-justify: inter-word;
      border-right: 3px solid #414141;

      @include respond(tab-land) {
        border-right: none;
        border-bottom: 3px solid #414141;
      }
    }

    .testimonial:last-child {
      border: none;
    }
  }

  #explore-by {
    h2 {
      text-shadow: 2px 2px 2px #00000026 !important;
    }
  }

  // .how-it-works {
  //   border-radius: 3rem;
  //   .how-r {
  //     min-width: 15%;
  //   }
  // }

  .white-line {
    width: 5px;
    height: 100%;
  }

  // Artist
  .artist-div {
    cursor: pointer;
    .card {
      border-radius: 1rem;
      .celeb-img {
        border-radius: 0.75rem;
      }
    }
  }

  // Sprite CSS start
  .brand-logo-adobe-logo,
  .brand-logo-amazon-logo,
  .brand-logo-amul-logo,
  .brand-logo-glenmark-logo,
  .brand-logo-hul-logo,
  .brand-logo-lupin-logo,
  .brand-logo-motherdairy-logo,
  .brand-logo-motorola-logo,
  .brand-logo-oneplus-logo,
  .brand-logo-panasonic-logo,
  .brand-logo-tata-logo,
  .brand-logo-thetimesofindia-logo {
    max-width: 100%;
    height: auto;
    background-size: 100%;
    background-image: url(../img/brands.png);
  }

  .brand-logo-adobe-logo {
    background-position: 0 0%;
    background-size: 100%;
  }
  .brand-logo-amazon-logo {
    background-position: 0 5.270007%;
    background-size: 100%;
  }
  .brand-logo-amul-logo {
    background-position: 0 12.074948%;
    background-size: 100%;
  }
  .brand-logo-glenmark-logo {
    background-position: 0 24.427995%;
    background-size: 100%;
  }
  .brand-logo-hul-logo {
    background-position: 0 33.202358%;
    background-size: 100%;
  }
  .brand-logo-lupin-logo {
    background-position: 0 48.183254%;
    background-size: 100%;
  }
  .brand-logo-motherdairy-logo {
    background-position: 0 66.98762%;
    background-size: 100%;
  }
  .brand-logo-motorola-logo {
    background-position: 0 73.765234%;
    background-size: 100%;
  }
  .brand-logo-oneplus-logo {
    background-position: 0 78.420039%;
    background-size: 100%;
  }
  .brand-logo-panasonic-logo {
    background-position: 0 81.795196%;
    background-size: 100%;
  }
  .brand-logo-tata-logo {
    background-position: 0 98.315018%;
    background-size: 100%;
  }
  .brand-logo-thetimesofindia-logo {
    background-position: 0 100%;
    background-size: 100%;
  }
  // Sprite CSS end

  // USP
  #USP {
    .uspcard {
      // FUNCTIONALITY
      perspective: 150rem;
      -moz-perspective: 150rem;
      // position: relative;
      height: 20rem;
      @include respond(tab-port) {
        height: 14rem;
      }
    }
    .uspcard__side {
      height: 20rem;
      @include respond(tab-port) {
        height: 14rem;
      }
      transition: all 0.8s ease;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 3rem;
      overflow: hidden;
    }

    .uspcard__side--back {
      transform: rotateY(180deg);
    }
    .uspcard:hover .uspcard__side--front {
      transform: rotateY(-180deg);
    }

    .uspcard:hover .uspcard__side--back {
      transform: rotateY(0);
    }

    .usp-img {
      width: 6rem;
      // height: 6rem;
      @include respond(tab-port) {
        width: 4.5rem;
        // height: 4.5rem;
      }
      @include respond(phone) {
        width: 3rem;
        // height: 3rem;
      }
    }
    .usp-img2 {
      width: 5.25rem;
      // height: 5.5rem;
      @include respond(tab-port) {
        width: 4rem;
        // height: 4.5rem;
      }
      @include respond(phone) {
        width: 2.5rem;
        // height: 3rem;
      }
    }

    .uspcard-front-text {
      font-size: 2rem;
      @include respond(tab-land) {
        font-size: 1.5rem;
      }
    }

    .uspcard-back-text {
      font-size: 1.5rem;
      @include respond(tab-land) {
        font-size: 1.25rem;
      }
      @include respond(phone) {
        font-size: 1.1rem;
      }
    }
  }

  #testimonials {
    .test-text {
      text-align: justify;
      text-justify: inter-word;
    }
  }
  #icon-link{
    text-decoration: none;
  }
}


// HTML Tags
// <img class="brand-logo-adobe-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABPAQMAAABrpGp2AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwQEBAAAAgiD/r25IQAEAAHcGDAkAARujsywAAAAASUVORK5CYII=">
// <img class="brand-logo-amazon-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABbAQMAAADz4yr7AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20ON6AAAAC4Nw3dAAFekHTJAAAAAElFTkSuQmCC">
// <img class="brand-logo-amul-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAC7AQMAAADlpzF5AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAB5JREFUeNrtwTEBAAAAwqD1T20JT6AAAAAAAAAADgYcfQABCWkdjwAAAABJRU5ErkJggg==">
// <img class="brand-logo-glenmark-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACOAQMAAACxEKdnAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABxJREFUeNrtwTEBAAAAwqD1T20MH6AAAAAAAPgYFaIAAZZ994AAAAAASUVORK5CYII=">
// <img class="brand-logo-hul-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABlAQMAAADNk0wmAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABtJREFUeNrtwTEBAAAAwqD1T20JT6AAAACAgwEPYwABefO84wAAAABJRU5ErkJggg==">
// <img class="brand-logo-lupin-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAFqAQMAAACasvVHAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAACVJREFUeNrtwQENAAAAwqD3T20ON6AAAAAAAAAAAAAAAAAA4NUANyYAAb42PEYAAAAASUVORK5CYII=">
// <img class="brand-logo-motherdairy-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACuAQMAAAC2vKJRAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAB1JREFUeNrtwTEBAAAAwqD1T20JT6AAAAAAAADgaRqCAAHtuS4EAAAAAElFTkSuQmCC">
// <img class="brand-logo-motorola-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABFAQMAAADKP0kQAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20LL6AAAOBqCoMAAaW3vvoAAAAASUVORK5CYII=">
// <img class="brand-logo-oneplus-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABHAQMAAACH9+gbAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwQENAAAAwqD3T20ON6AAAODYAArRAAHSCHjPAAAAAElFTkSuQmCC">
// <img class="brand-logo-panasonic-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAAuAQMAAACoDLaJAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABZJREFUeNpjYBgFo2AUjIJRMApGIgAABwIAAfF4gGYAAAAASUVORK5CYII=">
// <img class="brand-logo-tata-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEHAQMAAAAu2OnDAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAACFJREFUeNrtwQENAAAAwqD3T20ON6AAAAAAAAAAAACAewMoEQABP8iL7gAAAABJRU5ErkJggg==">
// <img class="brand-logo-thetimesofindia-logo" alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAAVAQMAAADGsUHnAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABBJREFUeNpjYBgFo2AUkAAAAzMAAc7zlW0AAAAASUVORK5CYII=">
