#editArtistPage {
  #artist-edit-form {
    border-radius: 3rem;
  }

  #addAudioClipForm {
    border-radius: 3rem;
  }

  #country-code {
    max-width: 7rem;
  }

  .trash-solid {
    width: 2rem;
  }

  .audioClipCard {
    border-radius: 3rem;
  }
}
