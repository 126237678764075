#footer {
  a {
    text-decoration: none;
  }

  // Logos
  .decilabs-logo {
    width: 10rem;
    @include respond(tab-port) {
      width: 9rem;
    }
  }
}
