#translatorRegistrationPage {
  #pointing_man {
    max-height: 95vh;
    width: auto;
  }

  .register-form {
    border-radius: 2rem !important;
    max-width: 900px;
    min-width: 300px;
  }

  .left-pane {
    min-width: 200px;
  }

  .icon {
    width: 1.2rem;
  }

  #country-code {
    max-width: 8rem;
  }
}
