#projects {
  .update,
  .search {
    cursor: pointer;
    width: 2rem;
  }

  .card {
    border-radius: 3rem;
  }
}
