#blog {
  max-width: 800px;
  margin: auto;
  .hero-image {
    max-width: 100%;
    max-height: 50rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .markdown-html {
    img {
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      max-height: 50rem;
    }
  }
}
