#landingPage {
     .hero-bg{
        background-image: url('/public/img/hero-bg.png');
        background-size: cover;
        /* Ensure the image covers the entire container */
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
    }
    .bg-image-sm {
        // height: 1000px;
        background-image: url('/public/img/hero-bg.png');
        background-size: cover;
        /* Ensure the image covers the entire container */
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
    }

    .main-heading {
        font-size: 3rem;
    
        @include respond(tab-land) {
          font-size: 2.5rem;
        }
    
        @include respond(tab-port) {
          font-size: 2rem;
        }
      }

    .icon {
        width: 30px;
    }

    .icon2 {
        width: 35px;
    }

    .icon3 {
        width: 23px;
        margin-left: 4px;
    }

    .icon4 {
        width: 27px;
        margin-left: 2px;
    }

    .card {
        border-radius: 2rem;
    }

    #contactus-hero-img {
        max-width: 40%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #country-code {
        max-width: 7rem;
    }

    .hero-desc {
        text-align: justify;
        text-justify: inter-word;
    }

    .bd-vd {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        height: auto;
        border-radius: 3rem;
    }

    @keyframes slide {
        from {
            transform: translateX(0)
        }

        to {
            transform: translateX(-100%)
        }
    }

    .logos {
        overflow: hidden;

    }

    .logs-slide {
        white-space: nowrap;
        animation: 30s slide infinite;
    }

    .logos:hover .logs-slide {
        animation-play-state: paused;
    }

    .logs-slide img {
        height: 80px;
        margin: 0 40px;
    }
    
    @media (width < 1300px) {
        .d-small-none {
            display: none !important;
        }
    }
    
    @media (width < 1000px) {
        .d-b1k-none {
            display: none !important;
        }
    }

    @media (width > 1000px) {
        .d-b1k {
            display: none !important;
        }
    }

    .wrap-it{
        width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    
  .home-brand-div {
    min-height: 80px;
  }
 

  .home-brands-60 {
    width: 60px;
  }

  .home-brands-80 {
    width: 80px;
  }

  .home-brands-100 {
    width: 100px;
  }

  .home-brands-120 {
    width: 120px;
  }

  .home-brands-140 {
    width: 140px;
  }

  .home-brands-160 {
    width: 160px;
  }

  .home-brands-180 {
    width: 180px;
  }

  .home-brands-200 {
    width: 200px;
  }

  .w-10{
    width: 10em;
  }

  .tlpl{
    padding-left: 2em;
    text-align: left !important;
  }


  .brand-logo-adobe-logo,
  .brand-logo-amazon-logo,
  .brand-logo-amul-logo,
  .brand-logo-glenmark-logo,
  .brand-logo-hul-logo,
  .brand-logo-lupin-logo,
  .brand-logo-motherdairy-logo,
  .brand-logo-motorola-logo,
  .brand-logo-oneplus-logo,
  .brand-logo-panasonic-logo,
  .brand-logo-tata-logo,
  .brand-logo-thetimesofindia-logo {
    max-width: 100%;
    height: auto;
  }


  
}