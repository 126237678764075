#audioPage {
  min-height: 80vh;

  .card {
    max-width: 800px;
    // background-color: $primary;
    border-radius: 2rem;

    .artist-icon {
      cursor: pointer;
      width: 1.75rem;
    }
    .artist-icon2 {
      cursor: pointer;
      width: 1.5rem;
    }

    .artist-details {
      min-width: 6.5rem;
    }

    a,
    a:link,
    &:visited {
      text-decoration: none;
    }

    .tags p {
      max-width: 18rem;

      @include respond(desktop) {
        max-width: 16rem;
      }

      @include respond(phone-sm) {
        max-width: 14rem;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // Avatars - transparent bg
  .avatar {
    width: 5rem;
    height: 5rem;
    @include respond(desktop) {
      width: 4rem;
      height: 4rem;
    }
    @include respond(tab-land) {
      width: 5rem;
      height: 5rem;
    }
    @include respond(tab-port) {
      width: 4rem;
      height: 4rem;
    }
  }

  // Ribbons
  .ribbon {
    position: absolute;
    max-width: 4rem;
    top: 1.25rem;
    left: -0.2rem;
  }
}
