#contactus {
  .icon {
    width: 30px;
  }
  .icon2 {
    width: 35px;
  }
  .icon3 {
    width: 23px;
    margin-left: 4px;
  }
  .icon4 {
    width: 27px;
    margin-left: 2px;
  }
  .card {
    border-radius: 2rem;
  }
  #contactus-hero-img {
    max-width: 40%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #country-code {
    max-width: 7rem;
  }
  .hero-desc {
    text-align: justify;
    text-justify: inter-word;
  }
}
