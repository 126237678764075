@mixin respond($breakpoint) {
  @if $breakpoint == phone-sm {
    @media only screen and (max-width: 576px) {
      @content;
    } //600px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 768px) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 992px) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 1200px) {
      @content;
    } //1200px
  }
  @if $breakpoint == desktop {
    @media only screen and (max-width: 1400px) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 1600px) {
      @content;
    } //1800
  }
}
