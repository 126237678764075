#pricingPage {
  #pricing-hero-img {
    max-width: 35%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .card {
    border-radius: 3rem;

    #gold-card-head,
    #gold-card-price {
      color: #e2b43e;
    }
    #platinum-card-head,
    #platinum-card-price {
      color: #ffa894;
    }
  }
  .hero-desc,
  .pricing-desc {
    text-align: justify;
    text-justify: inter-word;
  }

  // Ribbons
  .ribbon {
    position: absolute;
    max-width: 4rem;
    top: 2rem;
    left: -0.2rem;
  }
}

.outer {
  border-radius: 3rem;
  border-width: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-style: solid;
  border-color: #f7f4f4;
  .outer1 {
    padding-bottom: 9rem;
  }
  .silver {
    background: linear-gradient(
      to right,
      #cbcbcb,
      #cbcbcb,
      #cbcbcb,
      #e3e3e3,
      #e3e3e3,
      #fafafa
    );
  }
  .gold {
    background: linear-gradient(
      to right,
      #e1b543,
      #e1b543,
      #e1b543,
      #efd89b,
      #efd89b,
      #fcf8ee
    );
  }
  .platinum {
    background: linear-gradient(
      to right,
      #fedfdc,
      #fedfdc,
      #fedfdc,
      #eddddb,
      #eddddb,
      #dad9d9
    );
  }
  .e-learn {
    background: linear-gradient(
      to right,
      #fea344,
      #fea344,
      #fea344,
      #edc090,
      #edc090,
      #dfdad5
    );
  }
  .gold-consult-gs {
    margin-top: 15rem !important;
  }
  .inner-gc {
    margin-top: -8rem;
    background-color: white;
    border-radius: 2.5rem;
    margin-bottom: -0.5rem;
  }

  .inner-pc {
    background-color: white;
    margin-top: -8rem !important;
    padding-bottom: 2 rem !important;
    .plat-consult {
      margin-top: 4.48rem !important;
    }
  }

  .inner {
    margin-top: -8rem;
    background-color: white;
    border-radius: 3rem;
    .explr {
      margin-right: -21.5rem;
      margin-bottom: 1rem;
    }
    .price-btns {
      margin-left: 6rem;
    }
  }
}
