#voiceartists {
  label,
  select {
    cursor: pointer;
  }

  #voiceartists-artists {
    min-height: 50vh;
  }

  .right {
    .search {
      width: 1.5rem;
    }
    .clear-filter {
      width: 1.5rem;
    }
    button {
      border-radius: 0.5rem;
    }
  }
}
