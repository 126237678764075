#faqsPage {
    #services-hero {
        max-width: 45%;
        @include respond(tab-port) {
          max-width: 55%;
        }
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        // width: 50%;
      }
}
