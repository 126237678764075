#voiceArtistReg {
  #pointing_man {
    max-height: 95vh;
    width: auto;
  }
  // main {
  //   min-height: 90vh;
  // }
  .register-form {
    border-radius: 2rem !important;
    max-width: 900px;
    min-width: 300px;
  }

  .left-pane {
    min-width: 200px;
  }

  .icon {
    width: 1.2rem;
  }

  #country-code {
    max-width: 8rem;
  }

  // Slider
  // *********************************
  // The switch - the box around the slider
  .switch {
    position: relative;
    display: block;
    width: 218px;
    height: 30px;
    border-radius: 100px;
    backface-visibility: hidden;
  }

  // Hide default HTML checkbox
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  // The slider
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary;
    -webkit-transition: 0.4s;
    transition: all 0.4s;
    border-radius: 100px;
  }

  .slider:before {
    position: absolute;
    content: 'Showreel';
    height: 22px;
    width: 130px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: all 0.4s;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }

  input:checked + .slider {
    background-color: $secondary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $secondary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(80px);
    -ms-transform: translateX(80px);
    transform: translateX(80px);
    content: 'Sample Audio';
  }
}
