// logo animation definitions
// @keyframes volume-animate {
//   0% {
//     transform: scale(0);
//     opacity: 0;
//   }
//   15% {
//     transform: scale(1.1);
//     opacity: 1;
//   }
//   30%,
//   100% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }

@keyframes voices-animate {
  0%,
  30% {
    transform: translateX(-20px);
    opacity: 0;
  }
  45% {
    transform: translateX(5px);
    opacity: 1;
  }
  60%,
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

// @keyframes bazaar-animate {
//   0%,
//   30% {
//     transform: translateX(20px);
//     opacity: 0;
//   }
//   45% {
//     transform: translateX(-5px);
//     opacity: 1;
//   }
//   60%,
//   100% {
//     transform: translateX(0px);
//     opacity: 1;
//   }
// }

@keyframes show-animate {
  0%,
  60% {
    opacity: 0;
  }
  90%,
  100% {
    opacity: 1;
  }
}

@keyframes show-animate-fast {
  0%,
  10% {
    opacity: 0;
  }
  30%,
  100% {
    opacity: 1;
  }
}
