#aboutus {
  #aboutus-hero-img {
    max-width: 45%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutus-img {
    width: 8rem;
    height: auto;
  }

  .hero-desc,
  .about-desc {
    text-align: justify;
    text-justify: inter-word;
  }

  .about-pic {
    justify-content: start;
    @include respond(phone-sm) {
      justify-content: center;
    }
  }

  .about-name-desig {
    align-items: flex-start;
    @include respond(phone-sm) {
      align-items: center;
    }
  }
}
