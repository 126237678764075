#mixingMasteringPage {
    #sound-engg {
      max-width: 50%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    
  .home-brand-div {
    min-height: 80px;
  }

  .home-brands-60 {
    width: 60px;
  }

  .home-brands-80 {
    width: 80px;
  }

  .home-brands-100 {
    width: 100px;
  }

  .home-brands-120 {
    width: 120px;
  }

  .home-brands-140 {
    width: 140px;
  }

  .home-brands-160 {
    width: 160px;
  }

  .home-brands-180 {
    width: 180px;
  }

  .home-brands-200 {
    width: 200px;
  }

  // Sprite CSS start
  .brand-logo-adobe-logo,
  .brand-logo-amazon-logo,
  .brand-logo-amul-logo,
  .brand-logo-glenmark-logo,
  .brand-logo-hul-logo,
  .brand-logo-lupin-logo,
  .brand-logo-motherdairy-logo,
  .brand-logo-motorola-logo,
  .brand-logo-oneplus-logo,
  .brand-logo-panasonic-logo,
  .brand-logo-tata-logo,
  .brand-logo-thetimesofindia-logo {
    max-width: 100%;
    height: auto;
    background-size: 100%;
    background-image: url(../img/brands.png);
  }

  .brand-logo-adobe-logo {
    background-position: 0 0%;
    background-size: 100%;
  }
  .brand-logo-amazon-logo {
    background-position: 0 5.270007%;
    background-size: 100%;
  }
  .brand-logo-amul-logo {
    background-position: 0 12.074948%;
    background-size: 100%;
  }
  .brand-logo-glenmark-logo {
    background-position: 0 24.427995%;
    background-size: 100%;
  }
  .brand-logo-hul-logo {
    background-position: 0 33.202358%;
    background-size: 100%;
  }
  .brand-logo-lupin-logo {
    background-position: 0 48.183254%;
    background-size: 100%;
  }
  .brand-logo-motherdairy-logo {
    background-position: 0 66.98762%;
    background-size: 100%;
  }
  .brand-logo-motorola-logo {
    background-position: 0 73.765234%;
    background-size: 100%;
  }
  .brand-logo-oneplus-logo {
    background-position: 0 78.420039%;
    background-size: 100%;
  }
  .brand-logo-panasonic-logo {
    background-position: 0 81.795196%;
    background-size: 100%;
  }
  .brand-logo-tata-logo {
    background-position: 0 98.315018%;
    background-size: 100%;
  }
  .brand-logo-thetimesofindia-logo {
    background-position: 0 100%;
    background-size: 100%;
  }
  // Sprite CSS end

  .cm-l-15{
        margin-left: 1.5rem;

  }
}